import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/shared/auth.service';
import { Subscription } from 'rxjs';
import { Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { OTPVerificationModelComponent } from 'src/app/containers/pages/otp-verification-model/otp-verification-model.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";


@Component({
  selector: 'app-auto-login',
  templateUrl: './auto-login.component.html',
  styleUrls: ['./auto-login.component.scss']
})
export class AutoLoginComponent implements OnInit {

  store_id = '';
  email = '';
  errorMsg = 'Please wait......';

  is_store_login_by_email:any=false;
  is_store_login_by_phone:any=false;

  constructor(private route: ActivatedRoute,private authService: AuthService, 
    private _helper:Helper, 
    public commonService: CommonService,
    private _socialAuthService:SocialAuthService,
    private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        console.log(params); // { orderby: "price" }
        this.store_id = params.id;

        this.commonService.getStoreDetail({store_id: this.store_id}).then(res_data => {
          //console.log('store_data:',res_data.store);
          this.email = res_data.store.email;
          this.storeLogin();
        })
        //this.email = params.email
        //console.log('store_id',this.store_id); // price
      }
    );

    this.storeSettingDetail()

    


  }

  

  async storeLogin(){
    var device_token = localStorage.getItem('device_token');

    if (device_token == undefined || device_token == "undefined") {
      let uuid = this._helper.uuid;
      device_token = uuid;
      localStorage.setItem('device_token', device_token);

    }

    var login = {
      store_id: this.store_id,
      email: this.email,
      password: '1', //any number
      social_id: '',
      store_type: 0, //1 for substore
      device_type: 'web',
      device_token: device_token,
      login_by: 'manual',
      is_store_login_by_email:this.is_store_login_by_email,
      is_store_login_by_phone:this.is_store_login_by_phone,
      //captcha_token: this.captchaToken
    }

    //await this.authService.signIn(login);
    await this.authService.AutoStoreSignIn(login);
    
    
    setTimeout(() => {
      this.checkIfLogin()
    }, 1000);


  }

  checkIfLogin(){
    var storeData = JSON.parse(localStorage.getItem('storeData'));
    if(!storeData){
      this.errorMsg = 'Something went wrong Please try again';
        //console.log(this._helper.router.url)
        //this.authService.signOut(false, false);
        //this.router.navigate(['/store/login'])     //comented by dc
    }else{
      //this.router.navigate(['/app/dashboard']) //stage 1 commented by dc
      this.router.navigate(['/app/in-progress']) //stage 1 added by dc
      
    }
  }

  storeSettingDetail()
  {
    this.commonService.store_setting_details().then((store_data:any)=>{
      //console.log('ss',store_data);
      this.is_store_login_by_email=store_data.is_store_login_by_email;
      this.is_store_login_by_phone=store_data.is_store_login_by_phone;

    });
  }

}
