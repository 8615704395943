import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { max } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { Colors } from '../../../constants/colors.service';
import * as string_json from 'src/assets/i18n/en.json'
import { ChartService } from '../chart.service';

@Component({
  selector: 'app-one-doughnut-chart',
  templateUrl: './one-doughnut-chart.component.html',
  styleUrls: ['./one-doughnut-chart.component.scss']
})
export class OneDoughnutChartComponent implements AfterViewInit, OnDestroy, OnInit {

  
  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;

   chartData = {
    labels: ['Delivered'],
    datasets: [
      {
        label: '',
        borderColor: [Colors.getColors().themeColor1],
        backgroundColor: [
          Colors.getColors().themeColor1
        ],
        borderWidth: 2,
        data: [ 5]
      }
    ]
  }

  public constructor(private _commonService: CommonService, private _helper: Helper, private translate: TranslateService,private chartService: ChartService) { }

  ngOnInit() {
    if (this.shadow) {
      Chart.defaults.doughnutWithShadow = Chart.defaults.doughnut;
      Chart.controllers.doughnutWithShadow = Chart.controllers.doughnut.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.doughnut.prototype.draw.call(this, ease);
          const chartCtx = this.chart.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          Chart.controllers.doughnut.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    this.chartData = this.chartData
    //this.chartData.labels = this.labels

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'doughnutWithShadow' : 'doughnut',
      data: this.chartData,
      options: this.options,
      plugins: [this.chartService.centerTextPlugin]
    });



  }

  


  ngAfterViewInit(): void {
    /*if (this.shadow) {
      Chart.defaults.doughnutWithShadow = Chart.defaults.doughnut;
      Chart.controllers.doughnutWithShadow = Chart.controllers.doughnut.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.doughnut.prototype.draw.call(this, ease);
          const chartCtx = this.chart.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          Chart.controllers.doughnut.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'doughnutWithShadow' : 'doughnut',
      data: this.data,
      options: this.options,
      plugins: [this.chartService.centerTextPlugin]
    });*/
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
