import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private _image_settings;
  private _settings;
  
  constructor(private helper: Helper) {}


  get image_settings(){
    return this._image_settings;
  }


  get settings(){
    return this._settings;
  }

  getStoreDetail(data){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_DETAIL, data)
  }
  

  store_image_settings():Promise<boolean> {
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_IMAGE_SETTING, {}).then((res_data)=>{
        if(res_data.success){this._image_settings  = res_data.image_setting}
        resolve(true)
      })
    })
  }

  fetch_image_settings(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_IMAGE_SETTING, {})
  }

  last_fifteen_day_order_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_FIFTEEN_DAY_ORDER_DETAIL, {})
  }

  courier_data_list(json){
    return this.helper.http_get_method_requester(this.helper.POST_METHOD.COURIER_DATA_LIST, json)
  }

  getBanksLists(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_BANKS_LIST, {})
  }

  getCountryList(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_COUNTRY_LIST, {})
  }
  
  getCityList(country_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_CITY_LIST, {country_id})
  }

  getDeliveryList(city_id){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_DELIVERY_LIST_FOR_CITY, {city_id})
  }

  getAllDeliveryList(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.DELIVERY_LIST, {})
  }

  verify_captcha(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.VARIFY_CAPTCHA, json)
  }
  
  check_referral(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHECK_REFERRAL, json).then(res=>{
      return res;
    })
  }

  store_setting_details(){
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_SETTING_DETAIL, {}).then((res_data)=>{
        this._settings = res_data.setting;

       // return this._settings;
        resolve(this._settings);

    

      })
    })
  }

  otp_verification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.OTP_VERIFICATION, json)
  }
  
  store_verification(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.STORE_GENERATE_OTP, json)
  }

  
  get_average_sales_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_AVERAGE_SALES_DATA, json)
  }
  
  get_daily_sales_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_DAILY_SALES_DATA, json)
  }

  get_monthly_sales_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_MONTHLY_SALES_DATA, json)
  }
  get_orders_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_ORDERS_DATA, json)
  }
  get_yearly_sales_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_YEARLY_SALES_DATA, json)
  }

  // resend_otp_verification(json){
  //   return this.helper.http_post_method_requester(this.helper.POST_METHOD.RESEND_OTP_VERIFICATION, json)
  // }

  getTradesafeBearerToken(){
    //sandbox credentials
    //var tradeSafe_client_id = "98cf8784-7ea5-4504-ba57-a5e940b8544b";
    //var tradeSafe_client_secret = "3Q8VgffQ9JosnKaSFv6Mmrtcyz5Q0eZanjA12MfJ";

    //live credentials
    var tradeSafe_client_id = "99ff59b7-f06f-4b59-b830-6781e09d5803";
    var tradeSafe_client_secret = "AsKkAQuIrUneTjbFimYWG1E7VHwvyVxhwjejeKvu";

    var myHeaders = new Headers();
    myHeaders.append("content-type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "client_credentials");
    urlencoded.append("client_id", tradeSafe_client_id);
    urlencoded.append("client_secret", tradeSafe_client_secret);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    return fetch("https://auth.tradesafe.co.za/oauth/token", {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        var res= JSON.parse(result);
        //console.log(res.access_token)
        return {'status':1,'bearer_token':res.access_token}
        }
      )
      .catch(error => {
        console.log('error', error)
        return {status:0,bearer_token:error}
      }
      );

  }

  getTradesafeBankToken(bearer_token,requestQuery){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer "+bearer_token);
    myHeaders.append("Content-Type", "application/json");

    var graphql = JSON.stringify({
      //query: "mutation tokenCreate {\r\n    tokenCreate(\r\n        input: {\r\n       user: {\r\n            givenName: \"Gobuddy\",\r\n            familyName: \"Network\",\r\n            email: \"info@gobuddy.net\",\r\n            mobile: \"+27614714558\",\r\n      }\r\n        organization: {\r\n            name: \"Gobuddy Network\",\r\n            tradeName: \"Gobuddy\",\r\n            type: PRIVATE,\r\n            registrationNumber: \"2021/374067/07\",\r\n            taxNumber: \"000000000\"\r\n        }\r\n        bankAccount: {\r\n            accountNumber: \"00000000000\",\r\n            accountType: CHEQUE,\r\n            bank: SBSA\r\n        }\r\n        settings: {\r\n            payout: {\r\n                interval: IMMEDIATE,\r\n                refund: ACCOUNT,\r\n            }\r\n        }\r\n    }) {\r\n        id\r\n        name\r\n    }\r\n}",
      query: requestQuery,
      variables: {}
    })
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow'
    };

    //staging url 
    //var url = "https://api-developer.tradesafe.dev/graphql";
    //live url
    var url = "https://api.tradesafe.co.za/graphql";

    return fetch(url, {
      method: 'POST',
      headers: myHeaders,
      body: graphql,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => {
        var api_res = JSON.parse(result);
        //console.log('api_resdc:',api_res);
        return {'status':1, 'token':api_res.data.tokenCreate.id}
      })
      .catch(error => {
        console.log('error', error)
        return {'status':0, 'token':error}
      });
  }

  

}
