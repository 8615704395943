<!-- <div id="root" >
  <div class="fixed-background" >
    <main>
      <div class="container">
        <div class="row h-100"  >
          <div class="col-12 col-md-10 mx-auto my-auto">
            <div class="card auth-card" style="min-height: 570px;">
              <div class="position-relative image-side ">
                <p class=" h2" style="font-weight: 900;">{{'label-title.gear-up-your' | translate | uppercase}}</p>
    <p class="h2" style="color: #808080; font-weight: 900;">{{'label-title.business' | translate | uppercase}}</p>
              </div>
              <div class="form-side">
                <a routerLink="/">
                  <span class="logo-single"></span>
                </a>
                <h6 class="mb-4">{{ "pages.error-title" | translate }}</h6>
                <p class="display-1 font-weight-bold mb-5">404</p>
                <a [routerLink]="adminRoot" class="btn btn-primary btn-lg btn-shadow" (click)="CheckStoreLogin()" type="submit">
                  {{ "pages.go-back-home" | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div> -->

<style>
  
  .form_centerBox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
  }
  </style>
  
  <div class="form_centerBox">
    <div style="width:450px">
<div class="row" >
  <div class="col-sm-12 text-center">
    
      <!-- <span class="logo-single"></span> -->
      <img src="assets/logos/black_logo.png" class="text-center " width="80px"  >
   
    <h6 class="mb-4 mt-4">Ooops... looks like page not found!</h6>
    <p class="display-1 font-weight-bold mb-5">404</p>
    <a [routerLink]="adminRoot" class="btn btn-primary btn-lg btn-shadow" (click)="CheckStoreLogin()" type="submit">
      Go Back
    </a>
  </div>
</div>
</div>
</div>
