import { Component,  Input, OnInit } from '@angular/core';
import { Helper } from 'src/app/shared/helper';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit  {
    constructor(public _helper:Helper) { }
    ngOnInit(){
    }
}
