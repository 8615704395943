import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { max } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { Colors } from '../../../constants/colors.service';
import * as string_json from 'src/assets/i18n/en.json'
import { ChartService } from '../chart.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-current-courier-deliveries-chart',
  templateUrl: './current-courier-deliveries-chart.component.html',
  styleUrls: ['./current-courier-deliveries-chart.component.scss']
})
export class CurrentCourierDeliveriesChartComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;
  gb_client_id = '';
  gb_secrete_id = '';

   chartData = {
    labels: ['Gobuddy', 'The Courier Guy'],
    datasets: [
      {
        label: '',
        borderColor: ['#90cf4f','#0070c0'],
        backgroundColor: [
          '#90cf4f',
          '#0070c0'
        ],
        borderWidth: 2,
        data: [20, 90]
      }
    ]
  }

  public constructor(private _commonService: CommonService, private _helper: Helper, private translate: TranslateService,
                     private chartService: ChartService,private _orderService: OrderService) { }

  ngOnInit() {
    //this.gb_client_id = this._auth.loginStoreData.gb_client_id;
    //this.gb_secrete_id = this._auth.loginStoreData.gb_secrete_id
    this.gb_client_id = '001122334455';
    this.gb_secrete_id = '77553311559';
    //this.get_courier_chart_data()

    if (this.shadow) {
      Chart.defaults.doughnutWithShadow = Chart.defaults.doughnut;
      Chart.controllers.doughnutWithShadow = Chart.controllers.doughnut.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.doughnut.prototype.draw.call(this, ease);
          const chartCtx = this.chart.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          Chart.controllers.doughnut.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    this.chartData = this.data
    //this.chartData.labels = this.labels
    //this.chartData.datasets[0].data = [ data.courier_data.current_active_deliveries_per_provider.gobuddy, data.courier_data.current_active_deliveries_per_provider.courier_guy];

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'doughnutWithShadow' : 'doughnut',
      data: this.chartData,
      options: this.options,
      //plugins: [this.chartService.centerTextPlugin]
    });

   

  }

  /*
  get_courier_chart_data(){
    var json = {
      gb_client_id: this.gb_client_id,
      gb_client_secret: this.gb_secrete_id
    };
    this._orderService.get_courier_chart_data(json).then(data=>{
      
        if(data.success){
          
          

          if (this.shadow) {
            Chart.defaults.doughnutWithShadow = Chart.defaults.doughnut;
            Chart.controllers.doughnutWithShadow = Chart.controllers.doughnut.extend({
              // tslint:disable-next-line:typedef
              draw(ease) {
                Chart.controllers.doughnut.prototype.draw.call(this, ease);
                const chartCtx = this.chart.chart.ctx;
                chartCtx.save();
                chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
                chartCtx.shadowBlur = 10;
                chartCtx.shadowOffsetX = 0;
                chartCtx.shadowOffsetY = 10;
                chartCtx.responsive = true;
                Chart.controllers.doughnut.prototype.draw.apply(this, arguments);
                chartCtx.restore();
              }
            });
          }
      
          this.chartData = this.chartData
          //this.chartData.labels = this.labels
          this.chartData.datasets[0].data = [ data.courier_data.current_active_deliveries_per_provider.gobuddy, data.courier_data.current_active_deliveries_per_provider.courier_guy];
      
          const chartRefEl = this.chartRef.nativeElement;
          const ctx = chartRefEl.getContext('2d');
          this.chart = new Chart(ctx, {
            type: this.shadow ? 'doughnutWithShadow' : 'doughnut',
            data: this.chartData,
            options: this.options,
            //plugins: [this.chartService.centerTextPlugin]
          });
            
        }
    });
  }*/

  


  ngAfterViewInit(): void {
    /*if (this.shadow) {
      Chart.defaults.doughnutWithShadow = Chart.defaults.doughnut;
      Chart.controllers.doughnutWithShadow = Chart.controllers.doughnut.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.doughnut.prototype.draw.call(this, ease);
          const chartCtx = this.chart.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          Chart.controllers.doughnut.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'doughnutWithShadow' : 'doughnut',
      data: this.data,
      options: this.options,
      plugins: [this.chartService.centerTextPlugin]
    });*/
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
