import { Component, Input, ViewChild, ElementRef,OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Colors } from 'src/app/constants/colors.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { OrderService } from 'src/app/services/order.service';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-key-stat-delivery-line-chart',
  templateUrl: './key-stat-delivery-line-chart.component.html',
  styleUrls: ['./key-stat-delivery-line-chart.component.scss']
})
export class KeyStatDeliveryLineChartComponent implements OnInit, AfterViewInit, OnDestroy  {
  chartDataConfig: ChartService;
  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;
  gradient: any

  gb_client_id = '';
  gb_secrete_id = '';

  lineChartData = {
    labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    datasets: [
      {
        label: '',
        data: [23,34,22,22,44,27,45,34,54,44,55,34],
        borderColor: '#5b6ac7',
        pointBackgroundColor: '#5b6ac7',
        pointBorderColor: '#5b6ac7',
        pointHoverBackgroundColor: '#5b6ac7',
        pointHoverBorderColor: '#5b6ac7',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        borderWidth: 2,
        //fill: false,
        backgroundColor: "#5b6ac7",
        fillColor : '#5b6ac7', // Put the gradient here as a fill color
        strokeColor : "#5b6ac7",
        pointColor : "#5b6ac7",
        pointStrokeColor : "#5b6ac7",
        pointHighlightFill: "#5b6ac7",
        pointHighlightStroke: "#5b6ac7",
        lineTension: 0,  
      }
    ]
  }

  constructor(public _auth:AuthService,private _commonService: CommonService,private _orderService: OrderService) { }

  ngOnInit(): void {
    this.gb_client_id = this._auth.loginStoreData.gb_client_id;
    this.gb_secrete_id = this._auth.loginStoreData.gb_secrete_id
    //this.gb_client_id = '001122334455';
    //this.gb_secrete_id = '77553311559';

    this.get_key_stats_data()
   
  }


  get_key_stats_data(){
    var json = {
      gb_client_id: this.gb_client_id,
      gb_client_secret: this.gb_secrete_id
    };
    this._orderService.get_key_stats_data(json).then(data=>{
      //console.log('datak:',data)
        if(data.success){
          
          if (this.shadow) {
            Chart.controllers.lineWithShadow = Chart.controllers.line;
            Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
              // tslint:disable-next-line:typedef
              draw(ease) {
                Chart.controllers.line.prototype.draw.call(this, ease);
                const chartCtx = this.chart.ctx;
                chartCtx.save();
                chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
                chartCtx.shadowBlur = 10;
                chartCtx.shadowOffsetX = 0;
                chartCtx.shadowOffsetY = 10;
                chartCtx.responsive = true;
                chartCtx.stroke();
                Chart.controllers.line.prototype.draw.apply(this, arguments);
                chartCtx.restore();
              }
            });
          }
      
          this.lineChartData = this.lineChartData;

          var max= 0;
          var min= 0;
          var graphData = [data.key_stats_data.total_delivery_cost_per_month.jan,data.key_stats_data.total_delivery_cost_per_month.feb,data.key_stats_data.total_delivery_cost_per_month.mar,data.key_stats_data.total_delivery_cost_per_month.apr,data.key_stats_data.total_delivery_cost_per_month.may,data.key_stats_data.total_delivery_cost_per_month.jun,data.key_stats_data.total_delivery_cost_per_month.jul,data.key_stats_data.total_delivery_cost_per_month.aug,data.key_stats_data.total_delivery_cost_per_month.sep,data.key_stats_data.total_delivery_cost_per_month.oct,data.key_stats_data.total_delivery_cost_per_month.nov,data.key_stats_data.total_delivery_cost_per_month.dec]
          this.lineChartData.datasets[0].data = graphData;
          max = Math.max(...graphData)
          var slot_value = Math.ceil(max / 10)
          max = Math.ceil(max) + slot_value

          
          /*graphData.forEach((object,index)=>{
            console.log('object',object)
            this.lineChartData.datasets[0].data.push(object);
            if(max < object){
              max = object;
            }
            if(min > object){
              min = object;
            }
          })
          this.options.scales.yAxes[0].ticks.max = max;
          this.options.scales.yAxes[0].ticks.min = min;*/
          
          const chartRefEl = this.chartRef.nativeElement;
          const ctx = chartRefEl.getContext('2d');
      
          /*** Gradient ***/
          this.gradient = ctx.createLinearGradient(0, 0, 0, 400);
          this.gradient.addColorStop(0, 'rgba(16,41,195,1)');   
          this.gradient.addColorStop(1, 'rgba(246,248,251,0.5)');
          this.lineChartData.datasets[0].backgroundColor = this.gradient
      
          this.chart = new Chart(ctx, {
            type: this.shadow ? 'lineWithShadow' : 'line',
            data: this.lineChartData,
            options: this.options
          });

          this.chart.options.scales.yAxes[0].ticks.max = max
          this.chart.options.scales.yAxes[0].ticks.min = 0
          this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
          this.chart.update()
         
        
        }
    });
  }

  

  ngAfterViewInit(): void {
    /*if (this.shadow) {
      Chart.controllers.lineWithShadow = Chart.controllers.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      options: this.options
    });*/
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
