import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { BehaviorSubject } from 'rxjs';

export interface CategoryModel {
  _id:string,
  name:any,
  sequence_number:number,
  product_ids:any
}
export enum store_page_type {
  "approved", "blocked", "business_off"
}
@Injectable({ providedIn: 'root' })
export class ProfileService {

  profileObservable = new BehaviorSubject<any>(null);
  
  constructor(private helper: Helper) {}

  fetch(json = {}):Promise<any>{
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_DATA, json).then(data=>{
        resolve(data);
      })
    })
  }

  fetchDocuments():Promise<any>{
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_DOCUMENT_LIST, {type:2})    
  }

  fetchBank(json):Promise<any>{
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_BANK_DETAIL,json);
  }
  
  addBank(formData){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_BANK_DETAIL,formData).then(()=>{
      this.profileObservable.next(true);
    })
  }

  addStoreWarehouse(formData){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_STORE_WAREHOUSE,formData).then(()=>{
      this.profileObservable.next({})
    })
    
  }
  addStoreAddress(formData){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_STORE_ADDRESS,formData).then(()=>{
      this.profileObservable.next({})
    })
  }

  get_store_warehouses(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_WAREHOUSE, {})
  }
  getStoreWarehouseDetail(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_WAREHOUSE_DETAIL, json)
  }
  
  updateTradesafeBankToken(json){
    //return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_TRADESAFE_TOKEN, json)
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_TRADESAFE_TOKEN,json).then(()=>{
      this.profileObservable.next({})
    })
  }

  get_store_addresses(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_ADDRESSES, {})
  }
  getStoreAddressDetail(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_ADDRESS_DETAIL, json)
  }

  deleteBank({bank_holder_id,bank_holder_type,bank_detail_id,password}){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.DELETE_BANK_DETAIL,{bank_holder_id,bank_holder_type,bank_detail_id,password}).then(()=>{
      this.profileObservable.next(true);
    })
  }

  updateDocument(formData):Promise<any>{
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPLOAD_DOCUMENT, formData).then((data)=>{
      this.profileObservable.next(true);
      return data;
    })
  }

  weight_category_price_list(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_WEIGHT_CATEGORY_PRICE_LIST, {})
  }

  update(updateStoreForm){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE, updateStoreForm).then(()=>{
      this.profileObservable.next(true);
    })
  }

  async updateStoreDetail(data){
    // console.log(data)
  var res_data = await this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_STORE, data);
  this.profileObservable.next({})
  return res_data;
}

  check_domain(domain_name,domain_type){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHECK_DOMAIN, {domain_name:domain_name,domain_type:domain_type});
  }
  get_languages(){
    
    return this.helper.http_get_method_requester(this.helper.POST_METHOD.LIST_LANGUAGE, {})
  }

  list_reviews(start_date=null,end_date=null,page = 1,perPage = 10,query = {}){
    let json = {
      start_date,
      end_date,
      page,
      perPage,
      query  
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LIST_REVIEWS, json)

  }

  dashboard_customer_review(start_date=null,end_date=null){
    let json = {
      start_date,
      end_date,
    }
    return this.helper.dashboard_http_post_method_requester(this.helper.POST_METHOD.CUSTOMER_REVIEW, json)
  }
  busy_hour(start_date=null,end_date=null){
    let json = {
      start_date,
      end_date,
    }
    return this.helper.dashboard_http_post_method_requester(this.helper.POST_METHOD.BUSY_HOUR, json)
  }
  earning_tables(month=null,year=null){
    var json;
    if(month){
      json = {
        month,
        year,
      }
    }else{
      json = {
        year
      }
    }
    return this.helper.dashboard_http_post_method_requester(this.helper.POST_METHOD.EARNIG_REPORT, json)
  }
  dashboard_tables(start_date=null,end_date=null){
    let json = {
      start_date,
      end_date,
    }
    return this.helper.dashboard_http_post_method_requester(this.helper.POST_METHOD.DASHBOARD_TABLE, json)
  }

  getStoreDetail(json = {}):Promise<any>{
    return new Promise((resolve,rejects)=>{
      this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_DATA, json).then(data=>{
        resolve(data);
      })
    })
  }

}
