
import { Component,OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';

import { CommonService } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { Colors } from '../../../constants/colors.service';
import * as string_json from 'src/assets/i18n/en.json'
import { ChartService } from '../chart.service';
@Component({
  selector: 'app-emmissions-area-chart',
  templateUrl: './emmissions-area-chart.component.html',
  styleUrls: ['./emmissions-area-chart.component.scss']
})
export class EmmissionsAreaChartComponent implements OnInit,AfterViewInit, OnDestroy {

  

  

  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;
  gradient : any
  gradient_1 : any
  

  areaChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July','Aug','Sept','Oct','Nov','Dec'],
    datasets: [
      {
        label: 'Monthly km travelled',
        data: [44, 53, 50, 55, 50, 58, 50,44, 53, 50, 55, 50],
        borderColor: '#3a3838',
        pointBackgroundColor: '#3a3838',
        pointBorderColor: '#3a3838',
        pointHoverBackgroundColor: '#3a3838',
        pointHoverBorderColor: '#3a3838',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        fill: true,
        borderWidth: 2,
        backgroundColor: "#ff6c23",
        fillColor : '#ff6c23', // Put the gradient here as a fill color
        strokeColor : "#ff6c23",
        pointColor : "#fff",
        pointStrokeColor : "#ff6c23",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "#ff6c23",
        lineTension: 0,  
      },
      {
        label: 'Monthly estimated CO2 emissions (kg)',
        data: [64, 73, 70, 75, 70, 78, 70,74, 73, 70, 75, 70],
        borderColor: '#8d919d',
        pointBackgroundColor: '#8d919d',
        pointBorderColor: '#8d919d',
        pointHoverBackgroundColor: '#8d919d',
        pointHoverBorderColor: '#8d919d',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        fill: true,
        borderWidth: 2,
        backgroundColor: '#8d919d',
        lineTension: 0,  
      }
    ]
  }

  public constructor() { }

  ngOnInit(): void {
    if (this.shadow) {
      Chart.defaults.lineWithShadow = Chart.defaults.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        draw(ease): any {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    var max_value = Math.max(...this.data.datasets[0].data)
    var max_value2 = Math.max(...this.data.datasets[1].data)

    if(max_value2 > max_value){
      max_value = max_value2
    }
   
    var slot_value = Math.ceil(max_value / 10)
    max_value = Math.ceil(max_value) + slot_value

    
    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');

    /*** Gradient ***/
    this.gradient = ctx.createLinearGradient(0, 0, 0, 400);
    this.gradient.addColorStop(0, 'rgba(28,28,28,1)');   
    this.gradient.addColorStop(1, 'rgba(246,248,251,0.5)');
    //second gradient
    this.gradient_1 = ctx.createLinearGradient(0, 0, 0, 400);
    this.gradient_1.addColorStop(0, 'rgba(110,115,130,1)');   
    this.gradient_1.addColorStop(1, 'rgba(246,248,251,0.5)');

      this.data.datasets[0].backgroundColor = this.gradient
      this.data.datasets[1].backgroundColor = this.gradient_1
      this.chart = new Chart(ctx, {
        type: this.shadow ? 'lineWithShadow' : 'line',
        //data: this.data,
        data: this.data,
        options: this.options
      });

      this.chart.options.scales.yAxes[0].ticks.max = max_value
      this.chart.options.scales.yAxes[0].ticks.min = 0
      this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
      this.chart.update()
  }
  
  ngAfterViewInit(): void {
   /* if (this.shadow) {
      Chart.defaults.lineWithShadow = Chart.defaults.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        draw(ease): any {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      options: this.options
    });*/
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
