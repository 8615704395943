<style>
  
    .row{
      margin-right: 0 !important;
      margin-left: 0 !important;
      
    }
    .btn-primary:disabled{
      color: #fff;
    }
  
    /*.form_centerBox{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }*/
    .row .col-8{
      padding-left: 0px !important;
    }
    </style>
  <!-- <app-register-address-model #registerAddress></app-register-address-model> -->
  
  <ng-container>
      <div class="row mt-5">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">

            <div class="row">
                <div class="col-sm-12 text-center">
                  <img src="assets/logos/black_logo.png" width="80px">
                </div>
  
              </div>
              <!-- form start -->
              <div class="form-side mt-5 col-sm-12">
                  <!-- <a class="c-pointer">
                    <span class="logo-single"></span>
                  </a> -->
                  
                <!-- <h3 class="mb-4 text-center" style="font-weight: 600;">{{'menu-title.register' | translate}}<span>&ensp;|&ensp;</span><a class="text-primary mb-4"
                          routerLink="/store/login">{{'menu-title.login' | translate}}</a></h3> -->
                 
                  
              
                  
                  <form [formGroup]="RegisterForm" #form="ngForm" (ngSubmit)="onSubmit()">
                     
                  
                    
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label>Waybill</label>
                            <input type="text" (keypress)="helper.space_validation($event)" formControlName="waybill_number" class="form-control" name="waybill_number" required
                            placeholder="Please enter Waybill number" maxlength="50">
                            <span *ngIf="RegisterForm.get('waybill_number').errors?.required && RegisterForm.get('waybill_number').touched || form.submitted && RegisterForm.get('waybill_number').errors?.required"
                            class="text-danger"> Waybill is required!</span>
                            <span *ngIf="RegisterForm.get('waybill_number').errors?.maxlength"  class="text-danger">Max 50 Characters allowed</span >
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-12">
                            <label>Claim number</label>
                            <input type="text" (keypress)="helper.space_validation($event)" formControlName="claim_number" class="form-control" name="claim_number" required
                            placeholder="Please enter claim number " >
                            <span *ngIf="RegisterForm.get('claim_number').errors?.required && RegisterForm.get('claim_number').touched || form.submitted && RegisterForm.get('claim_number').errors?.required"
                            class="text-danger"> Claim number is required!</span>
                        </div>
                    </div>
                    
                
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <div class="d-flex justify-content-between align-items-center text-center mb-5" style="float: left;">
                                <button  class="btn btn-primary btn-lg btn-shadow text-center" type="submit">
                                  SUBMIT
                                </button>
                              </div>
                        </div>
                    </div>
                    
                    
                    
                  </form>
              
                </div>
  
              <!-- form end -->

          </div>
          <div class="col-sm-3"></div>
          
      </div>
   
  
  
     
     
  </ng-container>
  
  
    
    
    
