import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { SocketService } from "src/app/shared/socket.service";
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { Helper } from 'src/app/shared/helper';
import { UserWebsiteService } from 'src/app/services/user-website.service';
import { ProfileService } from 'src/app/services/profile.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topnav',
  styleUrls:['./topnav.component.scss'],
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  //adminRoot = environment.adminRoot;
  adminRoot = environment.adminRoot+'/in-progress'
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = '';
  languages: Language[];
  isSingleLang;
  isFullScreen = false;
  is_payment_fail = false;
  isDarkModeActive = false;
  no_of_days_remaining = 0;
  searchKey = '';
  store: any;
  IMAGE_URL: any;
  notifications:any = [];
  userWebsiteSubscriber: Subscription
  DEFAULT_IMAGE;
  profileSubscription:Subscription;
  constructor(private sidebarService: SidebarService,
    private _helper:Helper,
    private socket: SocketService,
    private authService: AuthService,
    private profilerService:ProfileService,
    private router: Router,
    private langService: LangService,
    private translateService: TranslateService,
    private _userWebsiteService: UserWebsiteService) {

    this.IMAGE_URL = _helper.IMAGE_URL
    this.DEFAULT_IMAGE = this._helper.DEFAULT_IMAGE_PATH.USER;

    this.languages = this.langService.supportedLanguages;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    var device_token = localStorage.getItem('device_token');
    if (device_token == "" || device_token == null || device_token == undefined) {
      let uuid = this._helper.uuid;
      localStorage.setItem('device_token', uuid);
    }  
    this.socket.listen('store'+device_token).subscribe( (res) =>{
      var res_data = JSON.parse(JSON.stringify(res));
      this.notifications.push(res_data);
      localStorage.setItem('notificationData',JSON.stringify(this.notifications));
      this._helper.onPush(res_data.messageCode,()=>{
        var messageCode = res_data.messageCode.toString();

        if(messageCode === '2071' || messageCode === '2074' || messageCode === '2070' || messageCode === '2088' ){
          if(res_data.messageData.is_schedule_order){
            this._helper.router.navigateByUrl('/app/order/schedule-orders');
          }else{
            this._helper.router.navigateByUrl('/app/order/today-orders');
          }
        } else if(messageCode === '2073' || messageCode === '2093'){
          this.onSignOut(true);          
        } else if(messageCode === '2061' || messageCode === '2062'|| messageCode === '2063'|| messageCode === '2064'|| messageCode === '2065'|| messageCode === '2066'|| messageCode === '2067'|| messageCode === '2069'){
          this._helper.router.navigateByUrl('/app/order/dispatch');
        } else if(messageCode === '2068'){
          this._helper.router.navigateByUrl('/app/history/history-list');
        } else if(messageCode === '2072'){
          window.location.reload()
        }

      });
      if(res_data.messageCode == 2093 || res_data.messageCode == 2073){
          this.onSignOut(true);
      }
    });
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }  

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    // this.langService.language = lang.code;
    this.translateService.use(lang.code);
    localStorage.setItem('theme_lang',lang.code);
    window.location.reload();
  }

  async ngOnInit(): Promise<void> {
    var user = this.authService.getStore()
    this.displayName = user.displayName;
    
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );

    // this.profilerService.fetch().then(storeData=>{
    //   console.log(storeData);
    // })

    this.profileSubscription = this.profilerService.profileObservable.subscribe((data)=>{
      if(data){
          this.profilerService.fetch().then(storeData=>{
            this.store = storeData.store_detail;
          })
      }
    });

    this.userWebsiteSubscriber = this._userWebsiteService._paymentGatewayObservable.subscribe(() => {
      this.store = JSON.parse(localStorage.getItem('storeData'))
    })

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if(this.profileSubscription){
      this.profileSubscription.unsubscribe();
    }
    if(this.userWebsiteSubscriber){
      this.userWebsiteSubscriber.unsubscribe()
    }
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(is_reload = false, is_navigate = true): void {
    // this.authService.signOut(is_reload, true)
    this.authService.onResetData();
    if (is_navigate) {
      this.router.navigate(['/store/login'])
    } else if (is_reload) {
      window.location.reload()
    }
  }

  searchKeyUp(event: KeyboardEvent): void {
    /*if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }*/
    this.router.navigate(['app/settings/payment']);
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }
}
