import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router } from '@angular/router';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Helper } from 'src/app/shared/helper';
import { RegisterAddressComponent } from 'src/app/containers/pages/register-address-model/register-address-model.component';
import { CommonService } from 'src/app/services/common.service';
import { RecaptchaComponent, ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { OTPVerificationModelComponent } from 'src/app/containers/pages/otp-verification-model/otp-verification-model.component';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { OrderService } from 'src/app/services/order.service';
declare var google: any;

@Component({
  selector: 'app-insurance-claim',
  templateUrl: './insurance-claim.component.html',
  styleUrls: ['./insurance-claim.component.scss']
})
export class InsuranceClaimComponent implements OnInit , OnDestroy{

  
 
  @ViewChild('registerForm') registerForm: NgForm;
  @ViewChild('registerAddress', { static: true }) registerAddress: RegisterAddressComponent;

  RegisterForm: FormGroup;

  waybill_number: any;
  claim_number: any; 

  is_store_mail_verification: boolean = false;
  is_store_sms_verification: boolean = false;
  is_referral_to_store: boolean = false;
  is_otp_requried: boolean = false
  
  remaining_time: number = 60;
  is_resend: boolean = false
  is_invalid_otp: boolean = false;
  isRegisterCompleted: boolean = false
  registerSubscription: Subscription;
  referral_code = "";
  applied_referral_code = "";
  

  //terms_conditios_url = environment.imageUrl+'/assets/privacy_policy/terms_conditions.pdf';

  @ViewChild('otpModel', { static: true }) otpModel: OTPVerificationModelComponent;
  is_use_captcha: boolean = false;
  email_error: "";
  number_error: "";
  min_max_number: any;

  show_password= false;
  show_confirm_password= false;

  constructor(private authService: AuthService,
    public helper: Helper,
    public commonService: CommonService,
    private _socialAuthService: SocialAuthService,
    private router: Router,
    private _orderService: OrderService) { }

  ngOnDestroy(): void {
    if (this.registerSubscription) {
      this.registerSubscription.unsubscribe();
    }
  }


  ngOnInit() {
    this.referral_code = "";
    /*this.registerSubscription = this.authService.loginSession.subscribe(sessionData => {
      if (sessionData && sessionData !== null) {
        this.helper.router.navigate([environment.adminRoot])
      }
    })*/
    
   
    this.get_setting_details()
    this._initForm()
  }

  


  get_setting_details() {
    var setting = this.commonService.settings;
    this.is_store_mail_verification = setting.is_store_mail_verification;
    this.is_store_sms_verification = setting.is_store_sms_verification;
    this.is_referral_to_store = setting.is_referral_to_store;
    if (this.is_store_sms_verification || this.is_store_mail_verification) {
      this.is_otp_requried = true
    }
    if (this.commonService.settings.is_use_captcha) {
      this.is_use_captcha = true
    } else {
      this.is_use_captcha = false
    }

    this.min_max_number = setting;
  }

  _initForm() {
    this.RegisterForm = new FormGroup({
      waybill_number: new FormControl(null, Validators.required),
      claim_number: new FormControl(null, Validators.required),
    })
  }

  

  

  async onSubmit() {
    
    
    
   
    this.timeInterval();
   
    if (this.authService.isAdminVerificationRequired) {
      this.otpModel.show({
        email: this.RegisterForm.value.email,
        phone: this.RegisterForm.value.phone,
        country_phone_code: this.RegisterForm.value.country_phone_code
      });
    } else {
    
      this.register();
    }

  }

  onComplete(event) {
    if (event) {
      this.RegisterForm.patchValue({
        is_phone_number_verified: true,
        is_email_verified: true,
      })
      this.register();
    }
  }

  // async verify_otp() {
  //   this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token) => {
  //       this.RegisterForm.patchValue({ otp: this.sms_otp })
  //       this.RegisterForm.patchValue({ email_otp: this.email_otp })
  //       this.RegisterForm.patchValue({ captcha_token: token })
  //       this.buttonDisabled = true;
  //       this.buttonState = 'show-spinner';
  //       this.register();
  //       this.buttonDisabled = false;
  //       this.buttonState = '';
  //     });
  // }

  async register() {
   
   
    //console.log('form data:', this.RegisterForm.value);
    this._orderService.update_claim_number(this.RegisterForm.value).then(res_data => {
      //console.log('res_data claim:',res_data)
      if (res_data.success) {
       
        
      } else {
        
       
      }
    })
  }

  // async resend_otp() {
  //   this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token) => {
  //       this.email_otp = ''
  //       this.sms_otp = ''
  //       var json = {
  //         type: 2,
  //         email: this.RegisterForm.value.email,
  //         phone: this.RegisterForm.value.phone,
  //         country_phone_code: this.RegisterForm.value.country_phone_code,
  //         device_type: 'web',
  //         captcha_token: token
  //       }
  //       this.commonService.resend_otp_verification(json).then(res_data => {
  //         if (res_data.success) {
  //           this.timeInterval()
  //         }
  //       })
  //     });
  // }


  

  

  timeInterval() {
    this.is_resend = false
    this.remaining_time = 60
    var time_interval = setInterval(() => {
      this.remaining_time = this.remaining_time - 1;
      if (this.remaining_time === 0) {
        clearInterval(time_interval)
        this.is_resend = true
      }
    }, 1000)
  }





}
