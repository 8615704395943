
    
    
    <ng-container>
      <div class="form_centerBox">
        <div style="width:450px">
      <div class="row">
         
          <div class="col-12" >
              <!-- form start -->
              <div class="form-side">
                 
                      <!-- <div class="row ">
                        <div class="col-sm-12 text-center mb-4">
                          <img src="assets/logos/black_logo.png" class="text-center" width="80px"  >
                        </div>
                        
                      </div> -->
                      
    
                      <h3 class="mb-4 text-center" style="font-weight: 600;"> {{errorMsg}} </h3>
              
                      
                      <br/><br/><br/><br/><br/><br/><br/><br/>
                      <br/><br/><br/><br/><br/><br/><br/><br/>
          </div>
          
      
    
      </div>
      
     </div>
    </div>
    <div class="text-center footerLink">
      <p class="pt-0 text-center"> <a target="_blank" href="/assets/privacy_policy/privacy_policy.pdf">Privacy Policy</a> &nbsp; &nbsp;  <a target="_blank" href="/assets/privacy_policy/terms_conditions.pdf">Terms of Service </a> 
        </p>
        <p class="pt-0 text-center">ⓒ Gobuddy 2024</p>
    <!-- <img src="assets/logos/black_logo.png" width="100px"  >
    <p class="mt-2 text-primary">ⓒ Gobuddy 2024- All rights reserved    </p> -->
    </div>
    </div>
     
      
      
    </ng-container>