import { Component,OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { CommonService } from 'src/app/services/common.service';
import { OrderService } from 'src/app/services/order.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-daily-delivery-line-chart',
  templateUrl: './daily-delivery-line-chart.component.html',
  styleUrls: ['./daily-delivery-line-chart.component.scss']
})
export class DailyDeliveryLineChartComponent implements OnInit ,AfterViewInit, OnDestroy{


  

  
  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;
  gb_client_id = '';
  gb_secrete_id = '';

  lineChartData = {
    labels: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#5b6ac7',
        pointBackgroundColor: '#5b6ac7',
        pointBorderColor: '#5b6ac7',
        pointHoverBackgroundColor: '#5b6ac7',
        pointHoverBorderColor: '#5b6ac7',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        borderWidth: 2,
        fill: false,
        //backgroundColor: "#5b6ac7",
        fillColor : '#5b6ac7', // Put the gradient here as a fill color
        strokeColor : "#5b6ac7",
        pointColor : "#5b6ac7",
        pointStrokeColor : "#5b6ac7",
        pointHighlightFill: "#5b6ac7",
        pointHighlightStroke: "#5b6ac7",
        lineTension: 0,  
      }
    ]
  }

  public constructor(public _auth:AuthService, private _commonService: CommonService,private _orderService: OrderService) { 
    
  }

  ngOnInit(): void {

    this.gb_client_id = this._auth.loginStoreData.gb_client_id;
    this.gb_secrete_id = this._auth.loginStoreData.gb_secrete_id
    //this.gb_client_id = '001122334455';
    //this.gb_secrete_id = '77553311559';
    //this.get_deliveries_chart_data()

    //console.log('this.datad:',this.data.datasets[0].data)
    if (this.shadow) {
      Chart.controllers.lineWithShadow = Chart.controllers.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    var arrayData = this.data.datasets[0].data
    var max_value = Math.max(...arrayData)
    var slot_value = Math.ceil(max_value / 10)
    max_value = Math.ceil(max_value) + slot_value

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      options: this.options
    });

    this.chart.options.scales.yAxes[0].ticks.max = max_value
    this.chart.options.scales.yAxes[0].ticks.min = 0
    this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
    this.chart.update()


    
    

  }

  /*get_deliveries_chart_data(){
    var json = {
      gb_client_id: this.gb_client_id,
      gb_client_secret: this.gb_secrete_id
    };
    this._orderService.get_deliveries_chart_data(json).then(data=>{
        if(data.success){
          this.lineChartData.datasets[0].data = data.key_stats_data.completed_deliveries_per_day;

          if (this.shadow) {
            Chart.controllers.lineWithShadow = Chart.controllers.line;
            Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
              // tslint:disable-next-line:typedef
              draw(ease) {
                Chart.controllers.line.prototype.draw.call(this, ease);
                const chartCtx = this.chart.ctx;
                chartCtx.save();
                chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
                chartCtx.shadowBlur = 10;
                chartCtx.shadowOffsetX = 0;
                chartCtx.shadowOffsetY = 10;
                chartCtx.responsive = true;
                chartCtx.stroke();
                Chart.controllers.line.prototype.draw.apply(this, arguments);
                chartCtx.restore();
              }
            });
          }
      
          var arrayData = this.lineChartData.datasets[0].data
          var max_value = Math.max(...arrayData)
          var slot_value = Math.ceil(max_value / 10)
          max_value = Math.ceil(max_value) + slot_value
      
          const chartRefEl = this.chartRef.nativeElement;
          const ctx = chartRefEl.getContext('2d');
          this.chart = new Chart(ctx, {
            type: this.shadow ? 'lineWithShadow' : 'line',
            data: this.lineChartData,
            options: this.options
          });
      
          this.chart.options.scales.yAxes[0].ticks.max = max_value
          this.chart.options.scales.yAxes[0].ticks.min = 0
          this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
          this.chart.update()
          

         
          
        
        }
    });
  }*/

  ngAfterViewInit(): void {
    /*if (this.shadow) {
      Chart.controllers.lineWithShadow = Chart.controllers.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      options: this.options
    });*/
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
