import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { AuthGuard } from '../shared/auth.guard';

@Directive({
    selector: '[HideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {
    @Input('HideIfUnauthorized') permission; // Required permission passed in

    constructor(private el: ElementRef, private authorizationService: AuthGuard) { }

    ngOnInit() {
        this.authorizationService.hasPermission(this.permission).then(auth => {
            if (!auth) {
                this.el.nativeElement.style.display = 'none';
            }
        })
    }
}