import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangPipe } from './lang.pipe'
import { RoundPipe } from './round.pipe'
import { SearchPipe } from './search.pipe'
import { ShortPipe } from './shorten.pipe'

@NgModule({
  declarations: [LangPipe,RoundPipe,SearchPipe,ShortPipe],
  imports: [CommonModule],
  exports: [LangPipe,RoundPipe,SearchPipe,ShortPipe],
})
export class PipeModule {}
