import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { max } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { Colors } from '../../../constants/colors.service';
import * as string_json from 'src/assets/i18n/en.json'

@Component({
  selector: 'app-sales-bar-graph',
  templateUrl: './sales-bar-graph.component.html',
  styleUrls: ['./sales-bar-graph.component.scss']
})
export class SalesBarGraphComponent implements AfterViewInit, OnDestroy, OnInit{

  
  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;
  chartData: any;
  max_number: number;
  min_number: number;
  concatinedArray: any = [];

  salesBarChartData = {
    datasets: [{
      borderColor: Colors.getColors().themeColor1,
      fill: false,
      backgroundColor: Colors.getColors().themeColor1,
      data: [11,23,2,1,22,34,11,2,44,44,23,12],
      borderWidth: 2,
      pointBackgroundColor: "white",
      pointBorderColor: Colors.getColors().themeColor1,
      pointBorderWidth: 2,
      pointHoverBackgroundColor: Colors.getColors().themeColor1,
      pointHoverBorderColor: "white",
      pointHoverRadius: 6,
      pointRadius: 4,
      label: '',
    }
    
  ]
  }
  labels = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  public constructor(private _commonService: CommonService, private _helper: Helper, private translate: TranslateService) { }

  ngOnInit() {
    //this.showSalesBarGraph();
    this.loadChartData();

  }

  loadChartData(){
    if (this.shadow) {
      Chart.defaults.global.datasets.barWithShadow = Chart.defaults.global.datasets.bar;
      Chart.defaults.barWithShadow = Chart.defaults.bar;
      Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.bar.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.2)';
          chartCtx.shadowBlur = 7;
          chartCtx.shadowOffsetX = 5;
          chartCtx.shadowOffsetY = 7;
          chartCtx.responsive = true;
          Chart.controllers.bar.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }


    

      this.concatinedArray = this.data.datasets[0].data
      var max_value = Math.max(...this.concatinedArray)
      var slot_value = Math.ceil(max_value / 10)
      max_value = Math.ceil(max_value) + slot_value

      const chartRefEl = this.chartRef.nativeElement;
      const ctx = chartRefEl.getContext('2d');
      this.chartData = this.data
      this.chartData.labels = this.labels
      this.chart = new Chart(ctx, {
        type: this.shadow ? 'barWithShadow' : 'bar',
        data: this.chartData,
        options: {
                  
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                      padding: 30,
                      usePointStyle: true,
                      fontSize: 12
                    }
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                          lineWidth: 1,
                          color: 'rgba(0,0,0,0.1)',
                          drawBorder: false
                        },
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                          min: 300,
                          max: 800,
                          padding: 20,
                          callback: function(value, index, ticks) {
                            return '' + value.toLocaleString('en-US');
                          }
                        }
                      }
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          display: false
                        }
                      }
                    ]
                  },
                  tooltips : {
                    enabled: false
                  },
                  "hover": {
                    "animationDuration": 0
                  },
                  "animation": {
                    "duration": 1,
                    "onComplete": function() {
                      var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
              
                      ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'bottom';
                      ctx.fillStyle= Colors.getColors().primaryColor;
              
                      this.data.datasets.forEach(function(dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function(bar, index) {
                          var data = dataset.data[index];
                          data = Math.round(data).toLocaleString()
                          ctx.fillText(data, bar._model.x, bar._model.y - 5);
                        });
                      });
                    }
                  },
        }
      });
      this.chart.options.scales.yAxes[0].ticks.max = max_value
      this.chart.options.scales.yAxes[0].ticks.min = 0
      this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
      this.chart.update()
  }

  showSalesBarGraph(){

    var json = {
      consumer_key: 'ck_1c08f37e5f3cbd73bbfd580b376b1c8ba9b7b21d',
      consumer_secret: 'cs_ff1a2997b30a138fe8584d18e90631ed380a2527'
    };
      this._commonService.get_yearly_sales_data(json).then(data1=>{
          if(data1.success){
            
            if (this.shadow) {
              Chart.defaults.global.datasets.barWithShadow = Chart.defaults.global.datasets.bar;
              Chart.defaults.barWithShadow = Chart.defaults.bar;
              Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
                // tslint:disable-next-line:typedef
                draw(ease) {
                  Chart.controllers.bar.prototype.draw.call(this, ease);
                  const chartCtx = this.chart.ctx;
                  chartCtx.save();
                  chartCtx.shadowColor = 'rgba(0,0,0,0.2)';
                  chartCtx.shadowBlur = 7;
                  chartCtx.shadowOffsetX = 5;
                  chartCtx.shadowOffsetY = 7;
                  chartCtx.responsive = true;
                  Chart.controllers.bar.prototype.draw.apply(this, arguments);
                  chartCtx.restore();
                }
              });
            }
      
      
            this.salesBarChartData.datasets[0].data = data1.data;
      
              this.concatinedArray = this.salesBarChartData.datasets[0].data
              var max_value = Math.max(...this.concatinedArray)
              var slot_value = Math.ceil(max_value / 10)
              max_value = Math.ceil(max_value) + slot_value
      
              const chartRefEl = this.chartRef.nativeElement;
              const ctx = chartRefEl.getContext('2d');
              this.chartData = this.salesBarChartData
              this.chartData.labels = this.labels
              this.chart = new Chart(ctx, {
                type: this.shadow ? 'barWithShadow' : 'bar',
                data: this.chartData,
                options: {
                          
                          legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                              padding: 30,
                              usePointStyle: true,
                              fontSize: 12
                            }
                          },
                          responsive: true,
                          maintainAspectRatio: false,
                          scales: {
                            yAxes: [
                              {
                                gridLines: {
                                  display: true,
                                  lineWidth: 1,
                                  color: 'rgba(0,0,0,0.1)',
                                  drawBorder: false
                                },
                                ticks: {
                                  beginAtZero: true,
                                  stepSize: 100,
                                  min: 300,
                                  max: 800,
                                  padding: 20,
                                  callback: function(value, index, ticks) {
                                    return '' + value.toLocaleString('en-US');
                                  }
                                }
                              }
                            ],
                            xAxes: [
                              {
                                gridLines: {
                                  display: false
                                }
                              }
                            ]
                          },
                          tooltips : {
                            enabled: false
                          },
                          "hover": {
                            "animationDuration": 0
                          },
                          "animation": {
                            "duration": 1,
                            "onComplete": function() {
                              var chartInstance = this.chart,
                                ctx = chartInstance.ctx;
                      
                              ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                              ctx.textAlign = 'center';
                              ctx.textBaseline = 'bottom';
                              ctx.fillStyle= Colors.getColors().primaryColor;
                      
                              this.data.datasets.forEach(function(dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                meta.data.forEach(function(bar, index) {
                                  var data = dataset.data[index];
                                  data = Math.round(data).toLocaleString()
                                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                });
                              });
                            }
                          },
                }
              });
              this.chart.options.scales.yAxes[0].ticks.max = max_value
              this.chart.options.scales.yAxes[0].ticks.min = 0
              this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
              this.chart.update()
              
          }
      });
  
      
    
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
