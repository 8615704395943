<!-- <div *ngIf="_helper.is_loading" class="loader_container">
    <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div> -->
<div class="container-fluid loader_container" *ngIf="_helper.is_loading">
    <div class="dashboard-wrapper">
        <div class="loading"></div>
    </div>
</div>