import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable()
export class MessagingService {
    
    constructor(private angularFireMessaging: AngularFireMessaging) {}


    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe((token) => {
                // console.log(token);
            },(err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload) => {
            console.log("new message received. ", payload);
        })
        this.angularFireMessaging.onMessage((payload) => {
            console.log("new message received. ", payload);
        })
       
    }

}