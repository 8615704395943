import { Component,OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartService } from 'src/app/components/charts/chart.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sales-daily-line-chart',
  templateUrl: './sales-daily-line-chart.component.html',
  styleUrls: ['./sales-daily-line-chart.component.scss']
})
export class SalesDailyLineChartComponent implements OnInit ,AfterViewInit, OnDestroy{

  
  
  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;

  lineChartData = {
    labels: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'],
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#5b6ac7',
        pointBackgroundColor: '#5b6ac7',
        pointBorderColor: '#5b6ac7',
        pointHoverBackgroundColor: '#5b6ac7',
        pointHoverBorderColor: '#5b6ac7',
        pointRadius: 4,
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        borderWidth: 2,
        fill: false,
        //backgroundColor: "#5b6ac7",
        fillColor : '#5b6ac7', // Put the gradient here as a fill color
        strokeColor : "#5b6ac7",
        pointColor : "#5b6ac7",
        pointStrokeColor : "#5b6ac7",
        pointHighlightFill: "#5b6ac7",
        pointHighlightStroke: "#5b6ac7",
        lineTension: 0,  
      }
    ]
  }

  public constructor(private _chartService: ChartService, private _commonService: CommonService) { }

  ngOnInit(): void {
   // this.showDailyChart()
    this.loadChartData()

  }

  loadChartData(){

    if (this.shadow) {
      Chart.controllers.lineWithShadow = Chart.controllers.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }
   

    var max_value = Math.max(...this.data.datasets[0].data)
   
    var slot_value = Math.ceil(max_value / 10)
    max_value = Math.ceil(max_value) + slot_value

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      //options: this.options
      options: {
        legend: {
          display: false
        },
        bezierCurve: false,
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: true,
                lineWidth: 1,
                color: 'rgba(0,0,0,0.1)',
                drawBorder: false
              },
              ticks: {
                beginAtZero: true,
                stepSize: 10,
                min: 10,
                max: 100,
                padding: 20,
                callback: function(value, index, ticks) {
                    return '' + value.toLocaleString('en-US');
                }
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        "hover": {
          "animationDuration": 0
        },
        "animation": {
          "duration": 1,
          "onComplete": function() {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
    
            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
    
            this.data.datasets.forEach(function(dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function(bar, index) {
                var data = dataset.data[index];
                data = Math.round(data).toLocaleString()
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
      }
    });

    

    this.chart.options.scales.yAxes[0].ticks.max = max_value
    this.chart.options.scales.yAxes[0].ticks.min = 0
    this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
    this.chart.update()

  }

  showDailyChart(){

    var json = {
      consumer_key: 'ck_1c08f37e5f3cbd73bbfd580b376b1c8ba9b7b21d',
      consumer_secret: 'cs_ff1a2997b30a138fe8584d18e90631ed380a2527'
    };
    this._commonService.get_monthly_sales_data(json).then(data1=>{
        if(data1.success){
          //this.lineChartData1.datasets[0].data = data.data

          if (this.shadow) {
            Chart.controllers.lineWithShadow = Chart.controllers.line;
            Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
              // tslint:disable-next-line:typedef
              draw(ease) {
                Chart.controllers.line.prototype.draw.call(this, ease);
                const chartCtx = this.chart.ctx;
                chartCtx.save();
                chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
                chartCtx.shadowBlur = 10;
                chartCtx.shadowOffsetX = 0;
                chartCtx.shadowOffsetY = 10;
                chartCtx.responsive = true;
                chartCtx.stroke();
                Chart.controllers.line.prototype.draw.apply(this, arguments);
                chartCtx.restore();
              }
            });
          }
         
      
          this.lineChartData.datasets[0].data = data1.data;
          
         
          var max_value = Math.max(...this.lineChartData.datasets[0].data)
         
          var slot_value = Math.ceil(max_value / 10)
          max_value = Math.ceil(max_value) + slot_value
      
          const chartRefEl = this.chartRef.nativeElement;
          const ctx = chartRefEl.getContext('2d');
          this.chart = new Chart(ctx, {
            type: this.shadow ? 'lineWithShadow' : 'line',
            data: this.lineChartData,
            //options: this.options
            options: {
              legend: {
                display: false
              },
              bezierCurve: false,
              responsive: true,
              maintainAspectRatio: true,
              tooltips: {
                enabled: false
              },
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      display: true,
                      lineWidth: 1,
                      color: 'rgba(0,0,0,0.1)',
                      drawBorder: false
                    },
                    ticks: {
                      beginAtZero: true,
                      stepSize: 10,
                      min: 10,
                      max: 100,
                      padding: 20,
                      callback: function(value, index, ticks) {
                          return '' + value.toLocaleString('en-US');
                      }
                    }
                  }
                ],
                xAxes: [
                  {
                    gridLines: {
                      display: false
                    }
                  }
                ]
              },
              "hover": {
                "animationDuration": 0
              },
              "animation": {
                "duration": 1,
                "onComplete": function() {
                  var chartInstance = this.chart,
                    ctx = chartInstance.ctx;
          
                  ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'bottom';
          
                  this.data.datasets.forEach(function(dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function(bar, index) {
                      var data = dataset.data[index];
                      data = Math.round(data).toLocaleString()
                      ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                  });
                }
              },
            }
          });
      
          
      
          this.chart.options.scales.yAxes[0].ticks.max = max_value
          this.chart.options.scales.yAxes[0].ticks.min = 0
          this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
          this.chart.update()
            
        }
    });

    
  }

  ngAfterViewInit(): void {
    /*if (this.shadow) {
      Chart.controllers.lineWithShadow = Chart.controllers.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.15)';
          chartCtx.shadowBlur = 10;
          chartCtx.shadowOffsetX = 0;
          chartCtx.shadowOffsetY = 10;
          chartCtx.responsive = true;
          chartCtx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }

    const chartRefEl = this.chartRef.nativeElement;
    const ctx = chartRefEl.getContext('2d');
    this.chart = new Chart(ctx, {
      type: this.shadow ? 'lineWithShadow' : 'line',
      data: this.data,
      options: this.options
    });*/
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
