import {CanActivate,Router,ActivatedRouteSnapshot,RouterStateSnapshot,CanActivateChild,UrlTree, ActivatedRoute,} from '@angular/router';
import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AuthService } from './auth.service';
import { Helper } from './helper';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

  // permissions: Array<any>;
  // is_free_trial_expire: Boolean = false;
  // is_payment_fail: Boolean = false;
  activateRouteSnapshot:any;

  constructor(private authService: AuthService, private router: Router, private helper:Helper,private _commonService:CommonService, private _activateRoutes: ActivatedRoute, private notifications: NotificationsService) { 
  }
  
  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    //console.log('canActivateChild')
    //console.log(this.activateRouteSnapshot)
    //console.log('route:',route)
    //console.log('state:',state)
    let check_permission = route.data['auth'];
    //console.log(check_permission)
    if (this.authService.loginStoreData) {
      // if (!check_permission || this.authService.is_main_store_login) {
      //   console.log('no_check_permission')
      //   return true;
      // } 
      if(this.authService.is_main_store_login){
        return this.checkPaymentFlow(check_permission, route,state)
      } else {
        if (!check_permission || this.authService.is_main_store_login) {
          //console.log('no_check_permission')
          return true;
        }
        return this.checkPaymentFlow(check_permission, route,state)
      }

    // else {
    //     console.log(check_permission)
    //     if (this.hasPermission(check_permission) && !this.permissions.includes(check_permission)) {
    //       this.router.navigate(['/app/dashboard'])
    //     }
    //     return this.hasPermission(check_permission)
    //   }
    } else {
      console.log('Not loaded login store data')
      return false
    }
    // if(this.authService.loginStoreData){
    //   console.log(this.authService)
    //   return this.checkPaymentFlow(check_permission,state)
    // }else{      
    //   return false;
    // }
  }

  // async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
  //   console.log('canActivate')
  //   console.log(this.activateRouteSnapshot.data)
  //   if(this.authService.loginStoreData){
  //     return this.checkPaymentFlow(route,state);
  //   }else{
  //     return false;
  //   }
  // }


  checkPaymentFlow(check_permission, route,state) : Promise<boolean> {
    return new Promise((resolve,rejects)=>{
      if (this.authService.is_main_store_login) {

        if(this._commonService.settings.is_upload_store_documents && !this.authService.is_document_uploaded){
          if(state.url.toString() === '/app/profile'){
            //console.log("---------------profile data----------------------");
            resolve(true);
          }else{
            //console.log("-----------------------profile data pending-------------------------");
            // this.helper.onError(null,'Documents not uploaded11...')
            // resolve(this.router.parseUrl('/app/profile'));
          }
        }

        //console.log('state.url: ',state.url)
        if((this.authService.is_approved && this.authService.is_verified) || state.url === '/app/profile'){
          if(state.url === '/app/settings/payment'){
            //console.log('state.payments: ')
            resolve(true);
          } else if(state.url === '/app/dashboard'){
            //console.log('app dashboard: ')
            resolve(true);
          } else if(state.url === '/app/users/delivery-boy-list'){
            //console.log('delivery list ')
            this.authService.is_store_can_add_provider ? resolve(true) : resolve(this.router.navigate(['/app/dashboard']));
          } else if(state.url === '/app/settings/table-booking-basic-settings' || state.url === '/app/settings/table-booking-cancellation-policy'){
            this.authService.is_provide_table_booking ? resolve(true) : resolve(this.router.navigate(['/app/dashboard']));
          } else if(state.url === '/app/menu/category'){
            //console.log('menu category: ')
            this.authService.is_store_can_create_group ? resolve(true) : resolve(this.router.navigate(['/app/dashboard']));
          }  else {  
            //console.log('resolve true: ')        
            resolve(true);
          }
        }else{
          if(!this.authService.is_approved){
            // this.helper.onError(null,'Not Approved...')
            this.notifications.create('Error', 'Store not approved!!', NotificationType.Error,
              { timeOut: 3000, showProgressBar: true }
            );
          }else if(!this.authService.is_verified){
            this.helper.onError(null,'Not Verified...')
            this.authService.signOut();
          }
          resolve(this.router.navigate(['/app/profile'])); //dc comment
          //resolve(this.router.navigate(['/app/account-maintenance'])); //added by dc
        }
        
      } else {
        if (state.url === '/app/settings/payment') {
          resolve(false);
        } else if(state.url === '/app/dashboard'){
          resolve(true);
        } else{
          // let check_permission ;
          //console.log(check_permission)
          if(check_permission){
            // check_permission = route.data.auth;
            this.hasPermission(check_permission).then((has_permission)=>{
              has_permission === true ? resolve(true) : resolve(this.router.navigate(['/app/dashboard']));
            })
          }else if(route.children[0] && route.children[0].data.auth){
            check_permission = route.children[0].data['auth'];
            this.hasPermission(check_permission).then((has_permission)=>{
              has_permission === true ? resolve(true) : resolve(this.router.navigate(['/app/dashboard']));
            })
          }else{
            resolve(false);
          }
        }
      }
    });
  }


  // hasPermission(check_permission): Promise<boolean> {
  //   return new Promise(async (resolve, rejects) => {     
  //     if(check_permission === 'noauth'){
  //       resolve(true);
  //     }else{
  //       if (!this.permissions) {
  //         this.authService.authPermission.subscribe(permissions => {
  //           if(permissions){
  //             this.permissions = permissions;
  //             resolve(this.permissions.includes(check_permission));
  //           }else{
  //             resolve(false);
  //           }
  //         })
  //       }else{
  //         resolve(this.permissions.includes(check_permission));
  //       }
  //     }
  //   })
  // }

  permissions: Array<any>;


  // constructor(private authService: AuthService, private router: Router) { }
  
  // async canActivateChild(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Promise<boolean> {
  //   let check_permission = route.data['auth'];
  //   console.log(check_permission)

  //   if(this.authService.loginStoreData){
  //     if(!check_permission || this.authService.is_main_store_login){
  //       console.log('no_check_permission')
  //       return true;
  //     }else{
  //       console.log(check_permission)
  //       if(this.hasPermission(check_permission) && !this.permissions.includes(check_permission)){
  //         this.router.navigate(['/app/dashboard'])
  //       }
  //       return this.hasPermission(check_permission)
  //     }
  //   } else {
  //     return false
  //   }
  // }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let check_permission = route.data['auth'];
    // console.log(check_permission)
    if(this.authService.loginStoreData){
      if(!check_permission || this.authService.is_main_store_login){
        return true;
      }else{
        return this.hasPermission(check_permission)
      }
    } else {
      return false
    }
  }



  hasPermission(check_permission): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {
      if (!this.permissions) {
        await this.authService.authPermission.subscribe(permissions => {
          this.permissions = permissions;
        })
      }      
      resolve(this.permissions.includes(check_permission));
    })
  }
}