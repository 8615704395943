import { NgModule } from '@angular/core';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { PolarChartComponent } from './polar-chart/polar-chart.component';
import { ScatterChartComponent } from './scatter-chart/scatter-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { RadarChartComponent } from './radar-chart/radar-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { SmallLineChartComponent } from './small-line-chart/small-line-chart.component';
import { CommonModule } from '@angular/common';
import { CompletedDeliveriesBarChartComponent } from './completed-deliveries-bar-chart/completed-deliveries-bar-chart.component';
import { CourierDeliveriesDoughnutChartComponent } from './courier-deliveries-doughnut-chart/courier-deliveries-doughnut-chart.component';
import { OneDoughnutChartComponent } from './one-doughnut-chart/one-doughnut-chart.component';
import { PriorityHalfDoughnutChartComponent } from './priority-half-doughnut-chart/priority-half-doughnut-chart.component';
import { KeyStatDeliveryLineChartComponent } from './key-stat-delivery-line-chart/key-stat-delivery-line-chart.component';
import { EmmissionsAreaChartComponent } from './emmissions-area-chart/emmissions-area-chart.component';
import { DailyDeliveryLineChartComponent } from './daily-delivery-line-chart/daily-delivery-line-chart.component';
import { SalesDailyLineChartComponent } from './sales-daily-line-chart/sales-daily-line-chart.component';
import { SalesBarGraphComponent } from './sales-bar-graph/sales-bar-graph.component';
import { DeliveriesInprogressPriorityChartComponent } from './deliveries-inprogress-priority-chart/deliveries-inprogress-priority-chart.component';
import { CurrentCourierDeliveriesChartComponent } from './current-courier-deliveries-chart/current-courier-deliveries-chart.component';
import { ReturnsDeliveriesLineChartComponent } from './returns-deliveries-line-chart/returns-deliveries-line-chart.component';

@NgModule({
  declarations: [
    AreaChartComponent,
    LineChartComponent,
    PolarChartComponent,
    ScatterChartComponent,
    BarChartComponent,
    RadarChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    SmallLineChartComponent,
    CompletedDeliveriesBarChartComponent,
    CourierDeliveriesDoughnutChartComponent,
    OneDoughnutChartComponent,
    PriorityHalfDoughnutChartComponent,
    KeyStatDeliveryLineChartComponent,
    EmmissionsAreaChartComponent,
    DailyDeliveryLineChartComponent,
    SalesDailyLineChartComponent,
    SalesBarGraphComponent,
    DeliveriesInprogressPriorityChartComponent,
    CurrentCourierDeliveriesChartComponent,
    ReturnsDeliveriesLineChartComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [
    AreaChartComponent,
    LineChartComponent,
    PolarChartComponent,
    ScatterChartComponent,
    BarChartComponent,
    RadarChartComponent,
    PieChartComponent,
    DoughnutChartComponent,
    SmallLineChartComponent,
    CompletedDeliveriesBarChartComponent,
    CourierDeliveriesDoughnutChartComponent,
    OneDoughnutChartComponent,
    PriorityHalfDoughnutChartComponent,
    KeyStatDeliveryLineChartComponent,
    EmmissionsAreaChartComponent,
    DailyDeliveryLineChartComponent,
    SalesDailyLineChartComponent,
    SalesBarGraphComponent,
    DeliveriesInprogressPriorityChartComponent,
    CurrentCourierDeliveriesChartComponent,
    ReturnsDeliveriesLineChartComponent
  ]
})

export class ComponentsChartModule { }
