import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { AuthService,Store } from '../shared/auth.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})


export class UserWebsiteService {
  
  private _paymentGatewayChanges = new BehaviorSubject<any>(null)
  _paymentGatewayObservable = this._paymentGatewayChanges.asObservable()

  constructor(private helper: Helper, private _auth:AuthService) {}

  getSettingDetail(){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_SETTING_DETAIL, {})
  }

  updateImage(json){
      this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_IMAGE, json).then(res_data => {
        if(res_data.success){
          var store = JSON.parse(localStorage.getItem('storeData'))
          store.image_url = res_data.store.image_url
          localStorage.setItem('storeData', JSON.stringify(store))
          this._paymentGatewayChanges.next({})
        }
      })
  }

  getStoreData(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_STORE_DATA,{});
  }

  storeUpdate(data){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE, data)
  }
  storeUpdateSetting(data){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_STORE_SETTING, data)
  }

  updateScriptTag(data){
      return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_SCRIPT_PAGE, data)
  }

  getScriptPages(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.SCRIPT_PAGES, {})
  }

  deleteScript(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.DELETE_SCRIPT_PAGES, json)
  }

  updategooglekey(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.UPDATE_GOOGLE_KEY_SETTING, json)
  }

}
