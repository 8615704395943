import { roLocale } from 'ngx-bootstrap/chronos';
import { environment } from 'src/environments/environment';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  role?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: string[];
}

const data: IMenuItem[] = [
  /*{
    icon: 'iconsminds-home',
    label: 'menu-title.dashboard',
    //to: `${adminRoot}/order/dashboard`
    to: `${adminRoot}/dashboard`
  },*/
  /*{
    icon: 'simple-icon-exclamation',
    label: 'menu-title.priority',
    to: `${adminRoot}/priority`
  },*/
  // {
  //   icon: 'simple-icon-notebook',
  //   label: 'menu-title.finance-view',
  //   to: `${adminRoot}/finance-view`
  // },
  {
    icon: 'iconsminds-shopping-cart',
    label: 'menu-title.create-order',
    to: `${adminRoot}/manual-create-order`
  },
  {
    icon: 'iconsminds-sand-watch-2',
    label: 'menu-title.in-progress',
    to: `${adminRoot}/in-progress`
  },
  // {
  //   icon: 'iconsminds-receipt-4',
  //   label: 'menu-title.warehouse-view',
  //   to: `${adminRoot}/warehouse-view`
  // },
  {
    icon: 'iconsminds-yes',
    label: 'menu-title.completed',
    to: `${adminRoot}/completed`
  },
  /*{
    icon: 'simple-icon-briefcase',
    label: 'menu-title.returns',
    to: `${adminRoot}/returns`
  },*/
  /*{
    icon: 'simple-icon-briefcase',
    label: 'menu-title.order-audit-trail',
    to: `${adminRoot}/order-audit-trail`
  },*/
  {
    icon: 'iconsminds-environmental-3',
    label: 'menu-title.emissions',
    to: `${adminRoot}/emissions`
  },
  {
    icon: 'iconsminds-coins',
    label: 'menu-title.prepaid-wallet',
    to: `${adminRoot}/prepaid-wallet`
  },
  /*{
    icon: 'iconsminds-dollar-sign-2',
    label: 'menu-title.potential-savings',
    to: `${adminRoot}/potential-savings`
  },*/
  /*
  {
    icon: 'iconsminds-shopping-cart',
    label: 'menu-title.create-order',
    to: `${adminRoot}/manual-create-order`
  },*/
  {
    icon: 'simple-icon-settings',
    label: 'menu-title.account-maintenance',
    to: `${adminRoot}/profile`
  },
  /*{
    icon: 'simple-icon-notebook',
    label: 'menu-title.order',
    to: `${adminRoot}`,
    subs: [
      {
        icon: 'iconsminds-shopping-cart',
        label: 'menu-title.create-order',
        to: `${adminRoot}/create-order`,
        role:'store/create_order',
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.running-order',
        to: `${adminRoot}/order`,
        subs: [
          {
            icon: 'iconsminds-receipt-4',
            label: 'menu-title.past-order',
            to: `${adminRoot}/order/past-orders`,
            role:'store/order'
          },
          {
            icon: 'simple-icon-book-open',
            label: 'menu-title.today-order',
            to: `${adminRoot}/order/today-orders`,
            role:'store/order'
          },
          {
            icon: 'iconsminds-sand-watch-2',
            label: 'menu-title.tomorrow-order',
            to: `${adminRoot}/order/tomorrow-orders`,
            role:'store/order'
          },
          {
            icon: 'iconsminds-over-time-2',
            label: 'menu-title.schedule-order',
            to: `${adminRoot}/order/schedule-orders`,
            role:'store/order'
          },
          {
            icon: 'iconsminds-checkout',
            label: 'menu-title.dispatch',
            to: `${adminRoot}/order/dispatch`,
            role:'store/dispatcher'
          },
          {
            icon: 'iconsminds-notepad',
            label: 'menu-title.table-booking',
            to: `${adminRoot}/order/table-booking`,
            role:'store/table-booking'
          },
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.history',
        to: `${adminRoot}/history`,
        subs: [
          {
            icon: 'simple-icon-calendar',
            label: 'menu-title.current-week',
            to: `${adminRoot}/history/current-week`,
            role:'store/history'
          },
          {
            icon: 'iconsminds-calendar-4',
            label: 'menu-title.current-month',
            to: `${adminRoot}/history/current-month`,
            role:'store/history'
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'menu-title.history',
            to: `${adminRoot}/history/history-list`,
            role:'store/history'
          },
          {
            icon: 'simple-icon-star',
            label: 'menu-title.reviews',
            to: `${adminRoot}/history/reviews`,
            role:'store/review'
          },
        ],
      },
    ],
  },
  {
    icon: 'iconsminds-coins',
    label: 'menu-title.earning',
    to: `${adminRoot}/earning`,
    subs: [
      {
        icon: 'simple-icon-calendar',
        label: 'menu-title.current-week',
        to: `${adminRoot}/earning/current-week`,
        role:'store/weekly_earning'
      }, {
        icon: 'iconsminds-calendar-4',
        label: 'menu-title.current-month',
        to: `${adminRoot}/earning/current-month`,
        role:'store/earning'
      }, {
        icon: 'simple-icon-grid',
        label: 'menu-title.earning',
        to: `${adminRoot}/earning/earnings`,
        role:'store/earning'
      },
    ],
  },
  {
    icon: 'iconsminds-pantone',
    label: 'menu-title.menu',
    to: `${adminRoot}/menu`,
    subs: [
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.menu',
        to: `${adminRoot}/menu/levels1`,
        subs: [
          {
            icon: 'simple-icon-film',
            label: 'menu-title.item',
            to: `${adminRoot}/menu/item-list`,
            role:'store/product'
          },
          {
            icon: 'simple-icon-organization',
            label: 'menu-title.category',
            to: `${adminRoot}/menu/category`,
            role:'store/group'
          }
        ],
      },
      {
        icon: 'simple-icon-layers',
        label: 'menu-title.modifier',
        to: `${adminRoot}/menu/levels2`,
        subs: [
          {
            icon: 'simple-icon-list',
            label: 'menu-title.modifier-group',
            to: `${adminRoot}/menu/modifier-group`,
            role:'store/modifier_group'
          },
          {
            icon: 'iconsminds-receipt-4',
            label: 'menu-title.import-export',
            to: `${adminRoot}/menu/import-data`,
            role:'store/modifier_group'
          }
        ],
      },
    ],
  },
  {
    icon: 'simple-icon-people',
    label: 'menu-title.users',
    to: `${adminRoot}/users`,
    subs: [
      {
        icon: 'simple-icon-people',
        label: 'menu-title.delivery-boy-list',
        to: `${adminRoot}/users/delivery-boy-list`,
        role:'store/providers'
      },
      {
        icon: 'iconsminds-clothing-store',
        label: 'menu-title.sub-stores',
        to: `${adminRoot}/users/sub-stores`,
        role:'store/sub_store'
      }
    ],
  },
  {
    icon: 'simple-icon-tag',
    label: 'menu-title.offers',
    to: `${adminRoot}/offers`,
    role:'store/promocode'
  },*/
  /*{
    icon: 'simple-icon-settings',
    label: 'menu-title.settings',
    to: `${adminRoot}/settings`,
    subs: [
      {
        icon: 'simple-icon-settings',
        label: 'menu-title.store-settings',
        to: `${adminRoot}/settings/store-settings`,
        role:'store/setting'
      },
      {
        icon: 'iconsminds-scooter',
        label: 'menu-title.delivery-settings',
        to: `${adminRoot}/settings/delivery-settings`,
        role:'store/deliveries'
      },
      {
        icon: 'iconsminds-clock',
        label: 'menu-title.store-open-close-settings',
        to: `${adminRoot}/settings/store-open-close-settings`,
        role:'store/store_time_setting'
      },
      {
        icon: 'simple-icon-settings',
        label: 'menu-title.table-booking',
        to: `${adminRoot}/settings/table-booking-basic-settings`,
        subs:[
          {
            icon: 'iconsminds-book',
            label: 'menu-title.table-booking-basic-settings',
            to: `${adminRoot}/settings/table-booking-basic-settings`,            
          },
          {
            icon: 'iconsminds-books',
            label: 'menu-title.cancellation-policy',
            to: `${adminRoot}/settings/table-booking-cancellation-policy`,
          },
        ]
      },
    ],
  },*/
  // {
  //   icon: 'iconsminds-support',
  //   label: 'menu-title.support',
  //   to: `${adminRoot}`,
  // }

];

export default data;
