import { Injectable, NgZone } from '@angular/core';
import { METHODS } from './http_methods';
import { GET_METHOD } from './http_methods'
import * as json2csv from 'json2csv'
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DATE_FORMAT, DEFAULT_IMAGE_PATH, ORDER_STATE,USER_TYPE } from 'src/app/views/app/constant'
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as string_json from 'src/assets/i18n/en.json'
import { TranslateService } from '@ngx-translate/core';
import { CONFIG } from '../constants/constant';

export interface IResponse {
   success: boolean,
   message: string,
   data: any
}

@Injectable({ providedIn: "root" })
export class Helper {

  store_base_url = 'https://edelivery.appemporio.net/'
  base_url = CONFIG.apiUrl;
  IMAGE_URL = CONFIG.imageUrl;
  public image_url = environment.imageUrl;
  public POST_METHOD = METHODS;
  public METHODS = METHODS;
  public USER_TYPE = USER_TYPE;
  public DATE_FORMAT = DATE_FORMAT;
  public GET_METHOD = GET_METHOD
  public ORDER_STATE = ORDER_STATE;
  public DEFAULT_IMAGE_PATH = DEFAULT_IMAGE_PATH;
  public height;
  public width;
  public is_loading;
  toastConfig = { timeOut: 3000, showProgressBar: true };

  selected_store_id = '';

  constructor(public http: HttpClient,
    public trans:TranslateService,
    public router: Router,public ngZone:NgZone,private notifications: NotificationsService,private translate: TranslateService) { }

  dashboard_http_post_method_requester(api_name, parameter) {
      return this.http.post<any>(environment.dashboardUrl + api_name, parameter).toPromise()
  }
  http_post_method_requester(api_name, parameter,is_loader = false) {
    // if(is_loader){
      this.is_loading = true;
    // }
    return this.http.post<any>(this.base_url + api_name, parameter).toPromise().then(data=>{
      setTimeout(() => {
        if(this.is_loading){
          this.is_loading = false;
        }
      }, 1000);
      return data;
    })
  }

  http_get_method_requester(api_name, parameter) {
    //console.log(api_name)
    if(api_name === '/admin/get_country_list'){

      return this.http.get<any>(this.base_url + api_name).toPromise()
    } else {
      return this.http.get<any>(this.base_url + api_name + '?' + 'store_id=' + parameter).toPromise()
    }
  }

  phone_number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) || charCode === 101) {
      return false;
    }
    return true;
  }

  only_character_validation(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  number_validation(evt,dotAllowed=true) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (dotAllowed && charCode == 46) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  }

  space_validation(evt){
    if(evt.code == "Space" && evt.target.value.length < 1){
      return false;
    }
    return true
  }

  special_char_validation(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  input_disable(){
    return false;
  }
  public downloadcsvFile(data:any, fields,filename='data'){

    if (fields.length !== 0) {
      fields.forEach(field => {
        field.label = this.trans.instant(field.label)
      });

      let csvData = this.convertToCSV(data, fields);
      let file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(file, filename +".csv");
    } else {
      this.notifications.create(
        this.trans.instant('alert.error'),
        this.trans.instant('label-title.no-data-found'),
        NotificationType.Error,
        this.toastConfig
      )
    }
  }

  public convertToCSV(objArray: any, fields) {

      let json2csvParser = new json2csv.Parser({ fields: fields});
      let csv = json2csvParser.parse(objArray);
      return csv;
  }


  export_csv(data, fieldname,filename='data') {
    var date_name:any = new Date().toLocaleDateString();
    date_name.replaceAll("/","_");
    filename = filename + '_' + date_name;

    let csvData = this.convert_to_csv(data, fieldname);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  check_email(email){
    var email_validation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(!email_validation.test(email)){
        return false;
    }
    return true;
}

check_number(num){
  var number_validation = /^((?!-0?(\.0+)?(e|$))-?(0|[1-9]\d*)?(\.\d+)?(?<=\d)(e-?(0|[1-9]\d*))?|0x[0-9a-f]+)$/i;

  if(!number_validation.test(num)){
      return false;
  }
  return true;
}

  private convert_to_csv(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'SR NO,';

    for (let index in headerList) {
      var dump = headerList[index].replaceAll("_"," ")
      row += dump.toUpperCase() + ',';
    }

    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i+1)+'';
        for (let index in headerList) {
            let head = headerList[index];
            if(array[i][head] && array[i][head].toString().includes(",")){
              line += ',' + array[i][head].toString().replaceAll(',','');
            }else{
              line += ',' + array[i][head];
            }
        }
        str += line + '\r\n';
    }
    return str;
  }
  get uuid() : string {
    return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  set_dynamic_height_width(class_name = '.dynamic_image'){
    var tag = document.querySelector(class_name);
    if(tag){
      this.width = tag.clientWidth;
      this.height = tag.clientHeight;
    }
  }

  clear_height_width(){
    this.height = this.width = undefined;
  }

  get_image(image_url) : string{
    return (this.height && this.width)  ? CONFIG.apiUrl+'/resize_image?width='+this.width+'&height='+this.height+'&format=jpg&image='+CONFIG.imageUrl+image_url : null;
  }

  onError(errorMessage,message = null): void {
    if(message !== null){      
      this.notifications.create(
        message,message,NotificationType.Error,
        this.toastConfig
      );
    }else{      
      errorMessage = string_json.default['error-code'][errorMessage] ? this.translate.instant('error-code.' + errorMessage) : null;
      if(errorMessage){
        this.notifications.create(
          this.translate.instant('alert.error'),errorMessage,NotificationType.Error,
          this.toastConfig
        );
      }
    }
  }

  onSuccess(successCode): void {
    if (string_json.default['success-code'][successCode]) {
      this.notifications.create(
        this.translate.instant('alert.success'),
        this.translate.instant('success-code.' + successCode),
        NotificationType.Success,
        this.toastConfig
      );
    }
  }

  onPush(pushCode,callback): void {
    if (string_json.default['push-code'][pushCode]) {
      const toast = this.notifications.create(
        this.translate.instant('alert.success'),
        this.translate.instant('push-code.' + pushCode),

        NotificationType.Success,
        this.toastConfig
      )
      if(pushCode === 2093){
        this.router.navigate(['/store/login'])
      }
      toast.click.subscribe((event) => {
        callback(event)
      });
    }
  }

  getWeekDay(date){
    date = new Date(date);
    var first = date.getDate() - date.getDay();
    var start_date:any = new Date(date.setDate(first))
    var last = first + 6;
    var end_date:any = new Date(date.setDate(last))

    if(first <= 0){
      end_date = new Date(date.setDate(last))
      end_date = new Date(new Date(end_date).setMonth(new Date(end_date).getMonth() + 1))
    }else{
      end_date = new Date(date.setDate(last))
    }

    start_date = new Date(new Date(start_date).setHours(0,0,0,0)).toUTCString()
    end_date = new Date(new Date(end_date).setHours(0,0,0,0)).toUTCString()

    return [start_date,end_date];
  }

  public storeFormattedId(unique_id){
    return  "STR"+("000000000000" + unique_id).slice(-11);
  }
  public formattedId(str_prefix, unique_id){
    return  str_prefix+("000000000000" + unique_id).slice(-11);
  }

  replaceDoubleInvertedComma(str){
    return str.toString().replaceAll('"','');
  }
  

  // onPrimary(): void{
  //   console.log(this.notifications)
  //   this.notifications.create(this.translate.instant('alert.primary'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Bare, { theClass: 'primary', timeOut: 3000, showProgressBar: true });
  // }

  // onSecondary(): void {
  //   this.notifications.create(this.translate.instant('alert.secondary'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Bare, { theClass: 'secondary', timeOut: 3000, showProgressBar: true });
  // }

  // onInfo(): void  {
  //   this.notifications.create(this.translate.instant('alert.info'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Info, { timeOut: 3000, showProgressBar: true });
  // }

  // onWarning(): void  {
  //   this.notifications.create(this.translate.instant('alert.warning'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Warn, { timeOut: 3000, showProgressBar: true });
  // }

  // onError(msg='Something Went Wrong',msgCode=2003): void  {
  //   this.notifications.create(msgCode,
  //     this.translate.instant(msg),
  //     NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  // }

  // onSuccess(): void  {
  //   this.notifications.create(this.translate.instant('alert.success'),
  //     this.translate.instant('alert.notification-content'),
  //     NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  // }

}
