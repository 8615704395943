import { TranslateService } from '@ngx-translate/core';
import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { max } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import { Helper } from 'src/app/shared/helper';
import { Colors } from '../../../constants/colors.service';
import * as string_json from 'src/assets/i18n/en.json'
import { AuthService } from 'src/app/shared/auth.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-completed-deliveries-bar-chart',
  templateUrl: './completed-deliveries-bar-chart.component.html',
  styleUrls: ['./completed-deliveries-bar-chart.component.scss']
})
export class CompletedDeliveriesBarChartComponent implements AfterViewInit, OnDestroy, OnInit {

  
  @Input() shadow = false;
  @Input() options;
  @Input() data;
  @Input() class = 'chart-container';
  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  chart: Chart;
  chartData: any;
  max_number: number;
  min_number: number;
  concatinedArray: any = [];

  gb_client_id = '';
  gb_secrete_id = '';

  completedDeliveriesBarChartData = {
    datasets: [{
      borderColor: '#1e275e',
      fill: false,
      //backgroundColor: Colors.getColors().themeColor1,
      backgroundColor: '#1e275e',
      data: [11,23,2,1,22,34,11,2,44,44,23,12],
      borderWidth: 2,
      pointBackgroundColor: "white",
      pointBorderColor: '#1e275e',
      pointBorderWidth: 2,
      pointHoverBackgroundColor: '#1e275e',
      pointHoverBorderColor: "white",
      pointHoverRadius: 6,
      pointRadius: 4,
      label: '',
    }
    /*,
    {
      borderColor: Colors.getColors().themeColor2,
      fill: false,
      backgroundColor: Colors.getColors().themeColor2,
      data: [],
      borderWidth: 2,
      pointBackgroundColor: "white",
      pointBorderColor: Colors.getColors().themeColor2,
      pointBorderWidth: 2,
      pointHoverBackgroundColor: Colors.getColors().themeColor2,
      pointHoverBorderColor: "white",
      pointHoverRadius: 6,
      pointRadius: 4,
      label: this.translate.instant('label-title.completed-order')
    },
    {
      borderColor: Colors.getColors().themeColor3,
      fill: false,
      backgroundColor: Colors.getColors().themeColor3,
      data: [],
      borderWidth: 2,
      pointBackgroundColor: "white",
      pointBorderColor: Colors.getColors().themeColor3,
      pointBorderWidth: 2,
      pointHoverBackgroundColor: Colors.getColors().themeColor3,
      pointHoverBorderColor: "white",
      pointHoverRadius: 6,
      pointRadius: 4,
      label: this.translate.instant('label-title.rejected-by-store')
    },
    {
      borderColor: Colors.getColors().themeColor4,
      fill: false,
      backgroundColor: Colors.getColors().themeColor4,
      data: [],
      borderWidth: 2,
      pointBackgroundColor: "white",
      pointBorderColor: Colors.getColors().themeColor4,
      pointBorderWidth: 2,
      pointHoverBackgroundColor: Colors.getColors().themeColor4,
      pointHoverBorderColor: "white",
      pointHoverRadius: 6,
      pointRadius: 4,
      label: this.translate.instant('label-title.total-deliveries')
    },
    {
      borderColor: Colors.getColors().themeColor5,
      fill: false,
      backgroundColor: Colors.getColors().themeColor5,
      data: [],
      borderWidth: 2,
      pointBackgroundColor: "white",
      pointBorderColor: Colors.getColors().themeColor5,
      pointBorderWidth: 2,
      pointHoverBackgroundColor: Colors.getColors().themeColor5,
      pointHoverBorderColor: "white",
      pointHoverRadius: 6,
      pointRadius: 4,
      label: this.translate.instant('label-title.total-orders')
    }*/
  ]
  }
  labels = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  public constructor(public _auth:AuthService,private _commonService: CommonService, private _helper: Helper, private translate: TranslateService,private _orderService: OrderService) {}

  ngOnInit() {

    //this.gb_client_id = this._auth.loginStoreData.gb_client_id;
    //this.gb_secrete_id = this._auth.loginStoreData.gb_secrete_id
    //this.gb_client_id = '001122334455';
    //this.gb_secrete_id = '77553311559';
    //this.get_deliveries_chart_data()


    if (this.shadow) {
      Chart.defaults.global.datasets.barWithShadow = Chart.defaults.global.datasets.bar;
      Chart.defaults.barWithShadow = Chart.defaults.bar;
      Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
        // tslint:disable-next-line:typedef
        draw(ease) {
          Chart.controllers.bar.prototype.draw.call(this, ease);
          const chartCtx = this.chart.ctx;
          chartCtx.save();
          chartCtx.shadowColor = 'rgba(0,0,0,0.2)';
          chartCtx.shadowBlur = 7;
          chartCtx.shadowOffsetX = 5;
          chartCtx.shadowOffsetY = 7;
          chartCtx.responsive = true;
          Chart.controllers.bar.prototype.draw.apply(this, arguments);
          chartCtx.restore();
        }
      });
    }




      this.concatinedArray = this.data.datasets[0].data
      //console.log('dd:',this.concatinedArray)
      var max_value = Math.max(...this.concatinedArray)
      var slot_value = Math.ceil(max_value / 10)
      max_value = Math.ceil(max_value) + slot_value

      const chartRefEl = this.chartRef.nativeElement;
      const ctx = chartRefEl.getContext('2d');
      this.chartData = this.data
      //this.chartData.labels = this.labels
      this.chart = new Chart(ctx, {
        type: this.shadow ? 'barWithShadow' : 'bar',
        data: this.chartData,
        options: {
                        
                  legend: {
                    display: false,
                    position: 'bottom',
                    labels: {
                      padding: 30,
                      usePointStyle: true,
                      fontSize: 12
                    }
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                          lineWidth: 1,
                          color: 'rgba(0,0,0,0.1)',
                          drawBorder: false
                        },
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                          min: 300,
                          max: 800,
                          padding: 20,
                          callback: function(value, index, ticks) {
                            return '' + value.toLocaleString('en-US');
                          }
                        }
                      }
                    ],
                    xAxes: [
                      {
                        gridLines: {
                          display: false
                        }
                      }
                    ]
                  },
                  tooltips : {
                    enabled: false
                  },
                  "hover": {
                    "animationDuration": 0
                  },
                  "animation": {
                    "duration": 1,
                    "onComplete": function() {
                      var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
              
                      ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                      ctx.textAlign = 'center';
                      ctx.textBaseline = 'bottom';
                      ctx.fillStyle= Colors.getColors().primaryColor;
              
                      this.data.datasets.forEach(function(dataset, i) {
                        var meta = chartInstance.controller.getDatasetMeta(i);
                        meta.data.forEach(function(bar, index) {
                          var data = dataset.data[index];
                          data = Math.round(data).toLocaleString()
                          ctx.fillText(data, bar._model.x, bar._model.y - 5);
                        });
                      });
                    }
                  },
        }
      });
      this.chart.options.scales.yAxes[0].ticks.max = max_value
      this.chart.options.scales.yAxes[0].ticks.min = 0
      this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
      this.chart.update()



    
      

  }

  /*
  get_deliveries_chart_data(){
    var json = {
      gb_client_id: this.gb_client_id,
      gb_client_secret: this.gb_secrete_id
    };
    this._orderService.get_deliveries_chart_data(json).then(data=>{
        if(data.success){
          var monthData = data.key_stats_data.completed_deliveries_per_month;
          this.completedDeliveriesBarChartData.datasets[0].data = [monthData.jan,monthData.feb,monthData.mar,monthData.apr,monthData.may,monthData.jun,monthData.jul,monthData.aug,monthData.sep,monthData.oct,monthData.nov,monthData.dec]

        
          if (this.shadow) {
            Chart.defaults.global.datasets.barWithShadow = Chart.defaults.global.datasets.bar;
            Chart.defaults.barWithShadow = Chart.defaults.bar;
            Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
              // tslint:disable-next-line:typedef
              draw(ease) {
                Chart.controllers.bar.prototype.draw.call(this, ease);
                const chartCtx = this.chart.ctx;
                chartCtx.save();
                chartCtx.shadowColor = 'rgba(0,0,0,0.2)';
                chartCtx.shadowBlur = 7;
                chartCtx.shadowOffsetX = 5;
                chartCtx.shadowOffsetY = 7;
                chartCtx.responsive = true;
                Chart.controllers.bar.prototype.draw.apply(this, arguments);
                chartCtx.restore();
              }
            });
          }
    
    
    
    
            this.concatinedArray = this.completedDeliveriesBarChartData.datasets[0].data
            console.log('dd:',this.concatinedArray)
            var max_value = Math.max(...this.concatinedArray)
            var slot_value = Math.ceil(max_value / 10)
            max_value = Math.ceil(max_value) + slot_value
    
            const chartRefEl = this.chartRef.nativeElement;
            const ctx = chartRefEl.getContext('2d');
            this.chartData = this.completedDeliveriesBarChartData
            this.chartData.labels = this.labels
            this.chart = new Chart(ctx, {
              type: this.shadow ? 'barWithShadow' : 'bar',
              data: this.chartData,
              options: {
                              
                        legend: {
                          display: false,
                          position: 'bottom',
                          labels: {
                            padding: 30,
                            usePointStyle: true,
                            fontSize: 12
                          }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          yAxes: [
                            {
                              gridLines: {
                                display: true,
                                lineWidth: 1,
                                color: 'rgba(0,0,0,0.1)',
                                drawBorder: false
                              },
                              ticks: {
                                beginAtZero: true,
                                stepSize: 100,
                                min: 300,
                                max: 800,
                                padding: 20,
                                callback: function(value, index, ticks) {
                                  return '' + value.toLocaleString('en-US');
                                }
                              }
                            }
                          ],
                          xAxes: [
                            {
                              gridLines: {
                                display: false
                              }
                            }
                          ]
                        },
                        tooltips : {
                          enabled: false
                        },
                        "hover": {
                          "animationDuration": 0
                        },
                        "animation": {
                          "duration": 1,
                          "onComplete": function() {
                            var chartInstance = this.chart,
                              ctx = chartInstance.ctx;
                    
                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'bottom';
                            ctx.fillStyle= Colors.getColors().primaryColor;
                    
                            this.data.datasets.forEach(function(dataset, i) {
                              var meta = chartInstance.controller.getDatasetMeta(i);
                              meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                data = Math.round(data).toLocaleString()
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                              });
                            });
                          }
                        },
              }
            });
            this.chart.options.scales.yAxes[0].ticks.max = max_value
            this.chart.options.scales.yAxes[0].ticks.min = 0
            this.chart.options.scales.yAxes[0].ticks.stepSize = slot_value
            this.chart.update()

         
          
        
        }
    });
  }*/

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }

}
